import type { ISbStoriesParams } from 'storyblok-js-client';

export function useStoryblokStory(path: string, parameters?: ISbStoriesParams) {
    const api = useStoryblokApi();

    const { data } = useAsyncData(
        path,
        () => api.getStory(useTrim(path, '/'), {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...parameters,
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
    );

    return computed(() => data.value?.data.story);
}
